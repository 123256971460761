@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Syne+Mono&display=swap");
@import url("bootstrap/dist/css/bootstrap.css");

$subHeader: #025951;
$secondaryColor: #5d8c8c;
$highlight: #f2388f;
$headerCol: #4d4d4d;
$teriaryColor: #d9c1c5;
$mainFont: "Roboto", sans-serif;
$headerFont: "Syne Mono", monospace;

body {
  font-family: $mainFont;
  font-size: 1.4vw;
  max-width: 100%;
}

h2 {
  font-family: $headerFont;
}

h1,
h2,
h3,
h4,
h5,
h6,
nav {
  font-size: 150%;
  font-family: $headerFont;
}

/* Style the header */

header {
  padding-bottom: 2vw;
  background-color: $headerCol;
  background-image: url("../img/headerBG.png");
  background-position: right;
  color: white;
  text-align: center;
  padding-top: 2vw;

  img {
    background-color: rgba(255, 255, 255, 0.493);
    opacity: 0.9;
    margin-right: 10vw;
    /*  background-image: url('../img/uniPattern.png');*/
    padding-top: 0.25vw;
  }
  h1 {
    font-size: 500%;
    padding-top: 10vw;
    text-shadow: 0.2vw 0.2vw $highlight;
  }
}

.margin {
  margin-top: 0.75vw;
}

nav {
  background-color: $subHeader;
  li {
    padding: 10px;
  }

  font: $mainFont;
  .navCust {
    padding-left: 10px;
    a:hover {
      border-color: #ffbf00;
      border-top: 2px solid;
      border-bottom: 2px solid;
      font-weight: bold;
      text-decoration: none;
      color: black;
    }
    a {
      text-decoration: none;
      color: black;
    }
    .active {
      border-bottom: 2px solid;
      font-weight: bold;
    }
  }
}

.footer {
  background-color: $subHeader;
  padding: 1vw;

  .social-icon {
    margin: 1vw;
    color: $highlight;
    /*background-image: url('../img/fabric.png');*/
  }
}

/* header styling */
.article {
  padding-top: 1vw;
  padding-bottom: 1vw;
  /* background-image: url('../img/arches.png');*/
  background-repeat: repeat;

  .custCol:nth-child(odd) {
    background-color: $secondaryColor;
  }

  p {
    text-align: justify;
    font-size: 1.3vw;
  }

  .custCol:nth-child(even) {
    background-color: $teriaryColor;
  }
}

.banner {
  .row > .col {
    padding-top: 1vw;
    padding-bottom: 1vw;
  }
}

/* Style the footer */
.footer {
  background-color: $subHeader;
  text-align: center;
  color: white;
}

.boxStyle {
  box-shadow: 0.1vw 0.1vw 0.1vw 0.1vw rgb(230, 230, 230);
}

/* nav styling */

/* Header Image */
.circular-square {
  border-radius: 45%;
}

.sample {
  img {
    width: 100%;
    height: 100%;
  }
  h5 {
    font-size: 2vw;
    text-align: center;
  }
}

main {
    background-color: $teriaryColor;
    .accordian{
        border:none;
    }
    .card{
        font-family: $headerFont;
        border:none;
        border-bottom: 3px solid $headerCol;
        border-radius: 0px;
    }
    .card-header{
        background-color: $secondaryColor;
        
        font-weight: bolder;
    }
    th{
      font-family: $headerFont;
    }

    .card-body{
        font-family: $mainFont;
        background-color: $teriaryColor;
    }

    .row:nth-child(even) {
      background-color: white;
    }
    .row:nth-child(odd) {
      background-color: lightgray;
    }
    
    .col:nth-child(odd) {
        text-align: right;
        font-weight: bolder;
        border-right: 1px solid black;
      }
}

.subHeading {
  background-color: $subHeader;
  background-image: "../img/subHeaderBg.png";
}

@media only screen and (max-width: 768px) {
  /* For mobile phones:*/
  [class*="col"] {
    width: 100%;
  }

  h5 {
    font-size: 6vw;
  }
  .article {
    p {
      font-size: 15px;
    }
    h5 {
      font-size: 20px;
    }
  }

  main {
    .subHeading {
      font-size: 25px;
    }
    h2 {
      font-size: 7vw;
    }
    p {
      font-size: 4vw;
    }

    h1 {
      font-size: 8vw;
    }
  }
  nav li {
    font-size: 5vw;
  }
  header {
    h1 {
      font-size: 10vw;
      margin-left: 20px;
    }

    h2 {
      font-size: 4vw;
      margin-left: 20px;
    }
  }
}

.text-center {
  text-align: center;
}
form {
  label {
    font-weight: bold;
  }

  input[type="text"],
  input[type="email"],
  select,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
}

button[type="submit"] {
  background-color: $highlight;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button[type="submit"]:hover {
  background-color: #83305d;
}
